import { ChangeEvent, useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { Link } from "react-router-dom"
import { PopupInfo } from "../../component/PopupInfo"
import { Helmet } from "react-helmet"
import eye from "../../images/eye.svg"
import eyeClose from "../../images/eye-closed.svg"


export const RegisterPage = () => {
    const auth = useContext(GlobalContext)
    const [openPopup, setOpenPopup] = useState(false)
    const [openPopupText, setOpenPopupText] = useState("false")

    const [showPassword, setShowPassword] = useState(false)

    const [form, setForm] = useState({ 
        email: "",
        name: "",
        surname: "",
        phone: "",
        password: "",
        passwordConf: ""
    })
    
    useEffect(()=>{
        if(auth.user.userToken != ""){
            window.location.replace('/account/');
        }
    },[])
    
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }
    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        auth.user.Registration(form.email, form.name, form.surname, form.phone, form.password, form.passwordConf).then(function(response){
            if(response.error == false){
                setForm({
                    email: "",
                    name: "",
                    surname: "",
                    phone: "",
                    password: "",
                    passwordConf: ""
                })
            }
            setOpenPopupText(response.msg)
            setOpenPopup(true)
        }).catch(function(error){
            alert("error")
        })
    }
    
    return(
        <>
            <Helmet>
                <title>Регистрация - Образовательный портал БФ «Живи сейчас»</title>
                <meta
                    name="description"
                    content='Благотворительный фонд «Живи сейчас» обучает медицинских и социальных работников паллиативному уходу за пациентами с диагнозом БАС и иным паллиативным статусом'
                />
            </Helmet>
            <section className="form-login">
                <div className="content">
                    <div className="form">
                        <h2>Регистрация</h2>
                        <form onSubmit={handleSubmit}>
                            <label>
                                <input type="email" maxLength={255} placeholder="Email" required name="email" value={form.email} onChange={changeHandler}  />
                            </label>
                            <label>
                                <input type="text" maxLength={255} placeholder="Фамилия" required name="surname" value={form.surname} onChange={changeHandler}  />
                            </label>
                            <label>
                                <input type="text" maxLength={255} placeholder="Имя" required name="name" value={form.name} onChange={changeHandler}  />
                            </label>
                            <label>
                                <input type="text" maxLength={25} placeholder="Номер телефона" required name="phone" value={form.phone} onChange={changeHandler}  />
                            </label>
                            <label className="show-password">
                                <input type={showPassword ? 'text' : 'password'} maxLength={40} placeholder="Пароль" required name="password" value={form.password} onChange={changeHandler}  />
                                <img 
                                    onClick={() => {setShowPassword(!showPassword)}}
                                    src={showPassword ? eye : eyeClose} 
                                />
                            </label>
                            <label>
                                <input type={showPassword ? 'text' : 'password'} maxLength={40} placeholder="Повторите пароль" required name="passwordConf" value={form.passwordConf} onChange={changeHandler}  />
                            </label>
                            <div>
                                <button>Создать</button>
                                <Link to={"/login/"}>Войти</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            {
                openPopup ? (
                    <PopupInfo text={openPopupText} close={setOpenPopup} />
                ):""
            }
        </>
    )
}